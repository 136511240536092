import React, { useContext } from 'react';
import classNames from 'classnames';
import { bool } from 'prop-types';
import { ExperienceContext, useConfigService, useStoreId } from '@thd-nucleus/experience-context';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { AddToCart } from '@thd-olt-component-react/add-to-cart';
import { Price } from '@thd-olt-component-react/price';
import { Image } from '@thd-olt-component-react/core-ui';
import { Typography, Tooltip } from '@one-thd/sui-atomic-components';
import { getPricingInfo } from '@thd-olt-component-react/product-set';
import { useAddToCartProps } from '../../hooks/useAddToCartProps';
import { TotalPrice } from './TotalPrice';
import { PackagesMiniContext } from '../PackagesMiniContext';
import './package-price-suite.scss';

const PackagePriceSuite = () => {
  const { channel } = useContext(ExperienceContext);
  const {
    packageModel: {
      isMajorAppliancePackage
    } = {},
    products
  } = useContext(PackagesMiniContext);
  const { cartReqParams, cartOptions, isAddToCartDisabled } = useAddToCartProps();
  const storeId = useStoreId();
  const enableApplianceDeliveryCharge = useConfigService('enableApplianceDeliveryCharge') || false;
  const enableFreeDeliveryForExchange = useConfigService('enableFreeDeliveryForExchange') || false;
  const applianceDeliveryChargeValue = useConfigService('applianceDeliveryChargeValue') || 29;
  const { isExchangeCustomer } = useThdCustomer() || {};

  const createPricingObject = (products) => {
    const {
      originalPrice,
      dollarOffPromo,
      dollarOff,
      totalPrice,
      mapAboveOriginalPrice,
      mapDetail,
      hideTotal
    } = getPricingInfo(
      products,
      enableApplianceDeliveryCharge,
      enableFreeDeliveryForExchange,
      applianceDeliveryChargeValue,
      isExchangeCustomer
    );
    const pricingObject = {
      pricing: {
        original: originalPrice,
        promotion: {
          dollarOff: dollarOff + dollarOffPromo,
          percentageOff: ((dollarOff + dollarOffPromo) * 100) / originalPrice
        },
        value: totalPrice,
        mapAboveOriginalPrice,
        mapDetail
      }
    };
    return { pricingObject, hideTotal };
  };

  const priceSuiteClasses = classNames('package-price-suite', {
    'package-price-suite--space': channel === 'mobile'
  });

  const hideFreeDelivery = enableApplianceDeliveryCharge && !isExchangeCustomer;
  const { pricingObject, hideTotal } = createPricingObject(products);

  return (
    <div className={priceSuiteClasses}>
      <div className="package-price-suite__price">
        {hideTotal ? (
          <>
            {/* eslint-disable max-len */}
            <Tooltip title="We've set this item's price lower than the manufacturer will let us advertise, so you'll have to add it to your cart to see our price. If you decide you don't want to purchase the item, you can remove it from your cart.">
              <Typography component="span" weight="bold" tabIndex="0" role="button" decoration="underline">
                See Lower Price In Cart
              </Typography>
            </Tooltip>
          </>
        ) : (
          <>
            <p className="package-price-suite__message">
              {'Price for Bundle: '}
            </p>
            {isMajorAppliancePackage ? (
              <Price
                basic
                large
                channel={channel}
                itemId=""
                product={pricingObject}
                displayEachUom={false}
                storeId={storeId}
                hideBadge
                disableOuterSpacing
              />
            ) : (
              <TotalPrice products={products} />
            )}
          </>
        )}
      </div>
      {isMajorAppliancePackage && (
        <div className="package-price-suite__delivery">
          {!hideFreeDelivery && (
            <>
              <Image
                src="https://assets.thdstatic.com/images/v1/alert-check-green.svg"
                alt="Free Delivery"
              />
              <h3 className="package-price-suite__delivery-text">Free Delivery</h3>
            </>
          )}
        </div>
      )}
      <AddToCart
        outline
        cartReqParams={cartReqParams}
        cartOptions={cartOptions}
        disabled={isAddToCartDisabled}
      >
        Add Bundle to Cart
      </AddToCart>
    </div>
  );
};

PackagePriceSuite.themeProps = {
  hideFinancingOffer: bool
};

PackagePriceSuite.defaultThemeProps = {
  hideFinancingOffer: false
};

PackagePriceSuite.displayName = 'PackagePriceSuite';

export { PackagePriceSuite };
