/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useMemo, useCallback, useState, useEffect, useContext
} from 'react';
import { string as stringType, number as numberType } from 'prop-types';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import {
  useDataModel,
  extend
} from '@thd-nucleus/data-sources';
import { useStoreId, useConfigService, ExperienceContext } from '@thd-nucleus/experience-context';
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import { DynamicRecsWrapper } from './DynamicRecsWrapper';
import {
  shouldDisplayPipInStock,
  shouldDisplayBatterySystems,
  shouldDisplayPipCollections,
  shouldDisplayPipAlternativeRecs,
  shouldDisplayVisuallySimilar,
  shouldDisplayReferrerRecommendations,
  shouldDisplayIrgcollections,
  shouldDisplayBBRCollections,
  shouldDisplayIrgaccessories,
  isLightingCategory
} from '../utils/product-utils';
import ZoneCard from '../utils/zone-card';
import { PipSemWrapper } from './PipSemWrapper';
import { pipRecsModel } from '../dataModel';

export const DynamicRecsWrapperPipComponent = (props) => {
  const {
    schemaName,
    anchorId,
    conditionNameForSchema,
    hydratorClassName,
    hydratorScrollBuffer,
    hydratorDelay,
    hydratorId: hydratorIdProp,
    hideWhenOtherInstanceHasSchema,
  } = props;

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const storeId = useStoreId();
  const ctx = useContext(ExperienceContext);
  const { referer } = ctx;
  const viewReferer = shouldDisplayReferrerRecommendations(referer);

  const fsRecsPipCollection = useConfigService('fs:isRecsCollectionEnable');
  const fspipRecsPositionAboveABTest = useConfigService('fs:pipRecsPositionAbove');
  const fspipRecsPositionBelowABTest = useConfigService('fs:pipRecsPositionBelow');
  const fspipRecsPositionDefaultABTest = useConfigService('fs:pipRecsPositionDefault');
  const pipRecsABTest = fspipRecsPositionAboveABTest || fspipRecsPositionBelowABTest || fspipRecsPositionDefaultABTest;

  const { data: productData } = useDataModel('clientOnlyProduct', {
    variables: {
      itemId: anchorId,
      storeId,
    },
    skip: !anchorId,
    ssr: false,
  });

  const hydrator = useMemo(
    () => ({
      tag: ZoneCard,
      ...(hydratorClassName && { className: hydratorClassName }),
      ...(hydratorScrollBuffer && { scrollBuffer: hydratorScrollBuffer }),
      ...(hydratorDelay && { delay: hydratorDelay }),
    }),
    [hydratorClassName, hydratorScrollBuffer, hydratorDelay]
  );

  const additionalProps = {};

  const schemaMap = useMemo(
    () => ({
      pipsem_above: fspipRecsPositionAboveABTest || (!pipRecsABTest && viewReferer),
      pipsem_below: fspipRecsPositionBelowABTest && mounted,
      pipinstock: shouldDisplayPipInStock(productData, referer),
      battery_collection: shouldDisplayBatterySystems(productData),
      pipcollection_above:
        !isLightingCategory(productData) && fsRecsPipCollection && shouldDisplayPipCollections(productData),
      pipcollection_below:
        isLightingCategory(productData) && fsRecsPipCollection && shouldDisplayPipCollections(productData),
      pip_alternatives:
        fspipRecsPositionDefaultABTest
        || (!pipRecsABTest && shouldDisplayPipAlternativeRecs(productData, referer)),
      visuallysimilar_1_0: shouldDisplayVisuallySimilar(productData),
      brand_based_collection: shouldDisplayIrgcollections(productData),
      irgaccessories: shouldDisplayIrgaccessories(productData),
      display_referrer_recommendations: (!shouldDisplayReferrerRecommendations(referer) && !pipRecsABTest)
        || fspipRecsPositionDefaultABTest,
      display_recommendation_flag_collections: shouldDisplayBBRCollections(productData),
      display_pipsem_above_referrer: (shouldDisplayReferrerRecommendations(referer) && !pipRecsABTest)
      || fspipRecsPositionAboveABTest,
      display_pipsem_below: fspipRecsPositionBelowABTest
    }),
    [
      productData,
      referer,
      fsRecsPipCollection,
      fspipRecsPositionAboveABTest,
      fspipRecsPositionBelowABTest,
      fspipRecsPositionDefaultABTest,
      pipRecsABTest,
      viewReferer,
      mounted,
    ]
  );

  const getHydratorId = () => {
    if (schemaMap[hideWhenOtherInstanceHasSchema]) {
      return null;
    }

    if (schemaName === 'pip_alternatives' && schemaMap[conditionNameForSchema]) {
      return fspipRecsPositionDefaultABTest ? 'RecsDefault' : 'recs_pip_alternatives';
    }

    if (schemaMap[conditionNameForSchema]) {
      return `recs_${schemaName}`;
    }

    return null;
  };

  if (schemaName === 'visuallysimilar_1_0') {
    additionalProps.anchorImage = productData?.product?.media?.image?.url;
  }

  const hydratorId = getHydratorId();
  const shouldRender = hydratorId !== null;

  if (!shouldRender) return null;

  return hydratorId === 'recs_pipsem' ? (
    <ZoneCard>
      <PipSemWrapper {...props} withReferrer={viewReferer} />
    </ZoneCard>
  ) : (
    <DynamicRecsWrapper
      {...props}
      {...additionalProps}
      hydrator={{ ...hydrator, id: hydratorIdProp || hydratorId }}
    />
  );
};

const propTypes = {
  schemaName: stringType.isRequired,
  conditionNameForSchema: stringType,
  referer: stringType,
  anchorId: stringType.isRequired,
  hydratorClassName: stringType,
  hydratorScrollBuffer: numberType,
  hydratorDelay: numberType,
  hydratorId: stringType,
  hideWhenOtherInstanceHasSchema: stringType,
};

const defaultProps = {
  referer: '',
  conditionNameForSchema: '',
  hydratorClassName: null,
  hydratorScrollBuffer: null,
  hydratorDelay: null,
  hydratorId: '',
  hideWhenOtherInstanceHasSchema: null,
};

DynamicRecsWrapperPipComponent.propTypes = propTypes;
DynamicRecsWrapperPipComponent.defaultProps = defaultProps;
DynamicRecsWrapperPipComponent.displayName = 'DynamicRecsWrapperPipComponent';
DynamicRecsWrapperPipComponent.dataModel = extend(
  pipRecsModel,
  DynamicRecsWrapper,
  PipSemWrapper,
  podFulFillmentUtils
);

export const DynamicRecsWrapperPip = withErrorBoundary(DynamicRecsWrapperPipComponent);

DynamicRecsWrapperPip.dataModel = extend(
  pipRecsModel,
  DynamicRecsWrapper,
  PipSemWrapper,
  podFulFillmentUtils
);
DynamicRecsWrapperPip.displayName = 'DynamicRecsWrapperPip';
DynamicRecsWrapperPip.propTypes = propTypes;
DynamicRecsWrapperPip.defaultProps = defaultProps;
