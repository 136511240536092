import {
  extend, shape, params, number, string, bool, arrayOf
} from '@thd-nucleus/data-sources';
import { CustomTransactionalPod } from './subcomponents/CustomTransactionalPod';

const ProductModel = extend({
  itemId: string(),
  dataSources: string(),
  identifiers: shape({
    itemId: string(),
    productLabel: string(),
    productType: string()
  }),
  info: shape({
    productGroup: string(),
    label: string()
  }),
  pricing: params({ storeId: string() }).shape({
    original: number({ float: true }),
    value: number({ float: true }),
    mapAboveOriginalPrice: bool(),
    promotion: shape({
      dollarOff: number({ float: true })
    }),
    promotionalAdjustments: arrayOf(shape({
      type: string(),
      description: shape({
        shortDesc: string(),
        longDesc: string()
      }),
      dollarOff: number({ float: true }),
      percentageOff: number({ float: true }),
      promoId: string(),
    })),
  })
},
CustomTransactionalPod.dataModel.product
);

export const transactionalDataModel = extend({
  packages: params({ itemId: string(), storeId: string(), packageId: string(), limit: number() })
    .arrayOf(
      shape({
        metadata: shape({
          packageId: string(),
          title: string(),
          brandName: string(),
          brandImage: shape({ url: string() }),
          heroImage: shape({ url: string() }),
          image: shape({ url: string() }),
          name: string(),
          defaultImage: shape({
            url: string()
          }),
          packageCategory: string()
        }),
        products: params().arrayOf(shape(ProductModel))
      })
    )
});