/* eslint-disable react/destructuring-assignment */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import { Buybox } from '@thd-olt-component-react/buybox';
import {
  extend,
  params,
  string,
  useDataModel,
  shape,
  bool,
} from '@thd-nucleus/data-sources';
import { productConfiguratorDataModel } from '../utils/productConfiguratorDataModel';
import { getAnchorSku, shouldDisplayConfigurator, flipPip } from '../utils/product-utils';
import { ProductConfigurator } from '../product-configurator';
import { PipFlipButton } from '../components/PipFlip/PipFlipButton';

const ConfiguratorAndPipFlipLayout = (props) => {
  const { renderPipFlip } = props;
  const ctx = useContext(ExperienceContext);
  const storeId = useStoreId();
  const { isServer } = ctx;
  const [itemId, setItemId] = useState(props.itemId);
  const [hasPipFlip, setHasPipFlip] = useState(false);
  const { configuredProductLabel } = useLifeCycleEventBus(
    'configurator.configurator_choice_selected_event'
  );

  const { data: productData } = useDataModel('product', {
    variables: {
      itemId,
      storeId,
    },
    skip: !itemId,
    ssr: false,
  });

  const anchorSku = getAnchorSku(productData, itemId);
  const displayConfigurator = shouldDisplayConfigurator(productData, isServer);

  useEffect(() => {
    setItemId(props.itemId);
  }, [props.itemId]);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_omsId_event', itemId);
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.hide_quantity', displayConfigurator);
  }, [productData]);

  const triggerPipFlip = () => {
    flipPip(
      configuredProductLabel,
      productData,
      hasPipFlip,
      itemId,
      setHasPipFlip,
      itemId,
      anchorSku,
      setItemId
    );
  };

  return (
    <>
      {displayConfigurator && (
        <div className="sui-p-3">
          <ProductConfigurator
            pipFlip={renderPipFlip && triggerPipFlip}
            itemId={itemId}
            hasPipFlip={renderPipFlip ? hasPipFlip : false}
          />
        </div>
      )}
      {!displayConfigurator && (
        <>
          <PipFlipButton
            productData={productData}
            displayConfigurator={displayConfigurator}
            triggerPipFlip={triggerPipFlip}
            hasPipFlip={hasPipFlip}
          />
        </>
      )}
    </>
  );
};

ConfiguratorAndPipFlipLayout.propTypes = {
  itemId: PropTypes.number.isRequired,
  renderPipFlip: PropTypes.bool,
};

ConfiguratorAndPipFlipLayout.defaultProps = {
  renderPipFlip: true
};

ConfiguratorAndPipFlipLayout.dataModel = extend(
  {
    product: params({ itemId: string().isRequired() }).shape({
      fulfillment: params({ storeId: string(), zipCode: string() }).shape({
        backordered: bool(),
      }),
      info: shape({
        gccExperienceOmsId: string(),
        dotComColorEligible: bool(),
        globalCustomConfigurator: shape({
          customButtonText: string(),
          customDescription: string(),
          customExperience: string(),
          customExperienceUrl: string(),
          customTitle: string(),
          customPosition: string()
        })
      }),
      identifiers: shape({
        productLabel: string(),
        productType: string(),
      })
    })
  },
  Buybox,
  productConfiguratorDataModel
);

ConfiguratorAndPipFlipLayout.displayName = 'ConfiguratorAndPipFlipLayout';

export { ConfiguratorAndPipFlipLayout };